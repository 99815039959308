<script setup>
import { useRoute } from 'vue-router'
import { okovi, plocastiParent } from '#nuxt-prepare'

const route = useRoute()

const a = ref(null)
const to = (x) => {
  if (route.path === x) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
}
const set = (x) => {
  a.value = a.value === null ? x : null
}
</script>

<template>
  <footer>
    <vSubscribe />
    <div class="wrapper">
      <div>
        <NuxtLink
          to="/plocasti-materijali"
          @click.click="to('/plocasti-materijali')"
        >
          <div class="h5">
            Pločasti materijali
          </div>
        </NuxtLink>
        <div
          v-if="Boolean(plocastiParent.length)"
          class="links"
        >
          <template
            v-for="ploca, keyPloca in plocastiParent"
            :key="keyPloca"
          >
            <NuxtLink
              v-if="!Boolean(ploca?.subcategories.length)"
              :to="'/plocasti-materijali/' + ploca.slug"
              class="link"
              @click.click="to('/plocasti-materijali/' + ploca.slug)"
            >
              {{ ploca.name }}
            </NuxtLink>
            <template v-else>
              <div class="expand">
                <NuxtLink
                  :to="'/plocasti-materijali/' + ploca.slug"
                  @click.click="to('/plocasti-materijali/' + ploca.slug)"
                >
                  {{ ploca.name }}
                </NuxtLink>
                <span
                  class="toggle"
                  @click="set(ploca.id)"
                >
                  <svg
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    fill="rgb(var(--color-rgb) / .5)"
                  >
                    <path
                      v-if="a !== ploca.id"
                      d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z"
                    />
                    <path
                      v-else
                      d=" M480-528 296-344l-56-56 240-240 240 240-56 56-184-184Z"
                    />
                  </svg>
                </span>
              </div>
              <div v-if="a === ploca.id">
                <NuxtLink
                  v-for="sub in ploca.subcategories"
                  :key="'plocasti' + sub.term_id"
                  :to="'/plocasti-materijali/' + ploca.slug + '/' + sub.slug"
                  class="a indent"
                  @click.click="to('/plocasti-materijali/' + ploca.slug + '/' + sub.slug)"
                >
                  {{ sub.name }}
                </NuxtLink>
              </div>
            </template>
          </template>

          <div class="expand">
            <NuxtLink :to="{ name: 'usluge' }">
              Usluge
            </NuxtLink>
            <span
              class="toggle"
              @click="a = a === null ? 'usluge' : null"
            >
              <svg
                height="24"
                viewBox="0 -960 960 960"
                width="24"
                fill="rgb(var(--color-rgb) / .5)"
              >
                <path
                  v-if="a !== 'usluge'"
                  d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z"
                />
                <path
                  v-else
                  d=" M480-528 296-344l-56-56 240-240 240 240-56 56-184-184Z"
                />
              </svg>
            </span>
          </div>
          <template v-if="a === 'usluge'">
            <NuxtLink
              to="/usluge/kantovanje"
              class="indent"
            >
              Kantovanje
            </NuxtLink>
            <NuxtLink
              to="/usluge/presovanje"
              class="indent"
            >
              Presovanje
            </NuxtLink>
            <NuxtLink
              to="/usluge/secenje-po-meri-plocastog-materijala"
              class="indent"
            >Sečenje po meri plocastog
              materijala</NuxtLink>
          </template>
        </div>
      </div>
      <div>
        <NuxtLink :to="{ name: 'okov-za-namestaj' }">
          <div class="h5">
            Okov za nameštaj
            <!-- <span class="label">Shop 🔥</span> -->
          </div>
        </NuxtLink>
        <div
          v-if="Boolean(okovi.length)"
          class="links"
        >
          <NuxtLink
            v-for="xx in okovi"
            :key="xx.id"
            :to="'/okov-za-namestaj/' + xx.slug"
            @click.click="to('/okov-za-namestaj/' + xx.slug)"
          >
            {{ xx.name }}
          </NuxtLink>
          <!-- <span class="count">{{ xx.count }}</span> -->
        </div>
      </div>
      <div>
        <NuxtLink :to="{ name: 'namestaj' }">
          <div class="h5">
            Nameštaj
          </div>
        </NuxtLink>
        <div class="links">
          <NuxtLink :to="{ name: 'namestaj-category', params: { category: 'kancelarijski-namestaj' } }">Kancelarijski
            nameštaj
          </NuxtLink>
          <NuxtLink :to="{ name: 'namestaj-category', params: { category: 'enterijer-stambeni-objekti' } }">
            Enterijer - stambeni objekti
          </NuxtLink>
          <NuxtLink :to="{ name: 'namestaj-category', params: { category: 'enterijer-objekti-javne-namene' } }">
            Enterijer - objekti javne namene
          </NuxtLink>
          <NuxtLink to="/namestaj/reference">
            Reference
          </NuxtLink>
        </div>
      </div>
      <div>
        <NuxtLink to="/">
          <div class="h5">
            StilLes
          </div>
        </NuxtLink>
        <div class="links">
          <NuxtLink :to="{ name: 'maloprodaja-plocastog-materijala-i-okova' }">Maloprodaja
            pločastog materijala
            i okova
          </NuxtLink>
          <NuxtLink :to="{ name: 'veleprodaja-plocastog-materijala' }">
            Veleprodaja pločastog materijala
          </NuxtLink>
          <NuxtLink :to="{ name: 'zaposlenje' }">
            Zaposlenje
          </NuxtLink>
          <NuxtLink :to="{ name: 'kontakt' }">
            Kontakt
          </NuxtLink>
          <NuxtLink :to="{ name: 'galerija' }">
            Galerija
          </NuxtLink>
          <NuxtLink :to="{ name: 'o-nama' }">
            O nama
          </NuxtLink>
        </div>
      </div>

      <div>
        <div class="h5 nolink">
          Kupovina i Privatnost
        </div>
        <div class="links">
          <NuxtLink :to="{ name: 'politika-privatnosti' }">
            Politika privatnosti
          </NuxtLink>
          <NuxtLink to="/opsti-uslovi-kupovine">
            Opšti uslovi kupovine
          </NuxtLink>
          <NuxtLink to="/dostava-robe-i-nacini-placanja">
            Dostava robe i načini plaćanja
          </NuxtLink>
          <NuxtLink :to="{ name: 'reklamacije' }">
            Reklamacije
          </NuxtLink>
        </div>
      </div>
      <div>
        <div class="h5 nolink df">
          Treba ti pomoć?
          <VToggleTheme />
        </div>
        <a
          href="tel:+381631093109"
          class="tel"
        >063 10 93 109</a>
        <a
          href="tel:+381657845537"
          class="tel"
        >065 78 45 537</a>
        <vSupport />
        <div class="info">
          Radnim danima od 8:00 – 20:00h<br>
          Subotom od 8:00 – 15:00h
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
.expand {
  display: grid;
  grid-template-columns: auto 24px;
  column-gap: 8px;
  /* align-items: start; */
}

.fx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pl {
  padding-top: 4px;
  padding-left: 16px;

  & div {
    font-size: 14px;
    padding: 4px 0;
  }
}

.count {
  color: rgb(var(--color-rgb) / .5)
}

footer {
  border-top: 1px solid rgb(var(--color-rgb) / .06);
  padding: 32px 24px 64px;
  background-color: rgb(var(--color-rgb) / .03);
  border-bottom: 1px solid rgb(var(--color-rgb) / .06);
}

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(6, 1fr);
}

.h5 {
  font-weight: 800;
  padding-bottom: 16px;
  line-height: 1;
}

.links {

  display: flex;
  flex-direction: column;

  & .expand {
    & a {
      padding: 2px 0;
    }
  }

  & a {
    font-size: 14px;
    padding: 4px 0;
    color: rgb(var(--color-rgb) / .5);

    &.router-link-exact-active {
      color: var(--color);
      font-weight: 600;
    }

    &.indent {
      padding: 4px 0 4px 24px;
    }

    &.arrow {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.a {
  display: block;
}

.tel {

  font-size: 21px;
  font-weight: 800;
  color: var(--brand-color);
  padding-bottom: 4px;
  display: block;
  font-variant-numeric: tabular-nums;
}

.info {
  padding-top: 12px;
  color: rgb(var(--color-rgb) / .5);
  font-size: 12px;
}

.nolink {
  color: rgb(var(--color-rgb) / .5);
  position: relative;
  /* outline: 1px dashed; */
  &.df {
    :deep(svg) {
      position: absolute;
      top: -4px;
      right: 0;
    }
  }
}

.label {
  font-weight: 400;
  background-color: var(--color);
  color: var(--color);
  padding: 0 6px 0 10px;
  font-size: 12px;
  height: 24px;
  display: inline-grid;
  place-items: center;
  line-height: 1;
  border-radius: 12px;
  vertical-align: middle;
}

.toggle {
  position: relative;
  cursor: pointer;
  user-select: none;

  & svg {
    vertical-align: middle;
  }

  &::after {
    content: "";
    position: absolute;
    top: -4px;
    left: -4px;
    width: 32px;
    height: 32px;
    display: block;

    border-radius: 50%;
  }
}

@media (max-width: 512px) {

  .wrapper {
    gap: 24px;
    grid-template-columns: 1fr;
  }

  .h5 {
    font-size: 24px;
  }

  .links {
    --padding: 8px;

    & .expand {
      align-items: center;

      & a {
        padding: var(--padding) 0;
      }
    }

    & a {
      padding: var(--padding) 0;
      font-size: 18px;

      &.indent {
        padding: var(--padding) 0 var(--padding) 24px;
      }
    }
  }
}

@media (hover:hover) {
  .toggle:hover {
    &::after {
      background-color: rgb(var(--color-rgb) / .06);
    }
  }
}
</style>
