<script setup>
const toasts = useToasts()
defineProps({
  toast: {
    type: Object,
    required: true
  }
})
const close = id => toasts.removeToast(id)
</script>

<template>
  <div :class="['toast', { error: toast?.error }]">
    <div
      v-interpolation="() => close(toast.id)"
      v-sanitize="toast.content"
      class="pt"
    />
    <div>
      <svg
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        class="icon"
        @click="toasts.removeToast(toast.id)"
      >
        <path
          fill="currentColor"
          d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"
        />
      </svg>
    </div>
  </div>
</template>

<style scoped>
.toast {
  background-color: #000;
  margin-bottom: 8px;
  min-height: 42px;
  border-radius: 3px;
  color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 14px 16px;
  font-size: 13px;
  display: grid;
  grid-template-columns: auto 24px;
  gap: 16px;

  &.error {
    background-color: red;
  }
}

.pt {
  padding: 4px 0;
}

:deep(strong) {
  font-weight: 800;
}

.icon {
  vertical-align: middle;
  cursor: pointer;
}
</style>
