<script setup>
const x = useToasts()
</script>

<template>
  <div class="toasts">
    <vToast
      v-for="toast, key in x.toasts"
      :key="key"
      :toast="toast"
    />
  </div>
</template>

<style scoped>
.toasts {
  position: fixed;
  bottom: 16px;
  left: 24px;
  width: 100%;
  max-width: 400px;
  z-index: 20;
}

@media (max-width: 512px) {
  .toasts {
    width: calc(100vw - 32px);
    max-width: none;
    top: 24px;
    /* left: 8px;
    right: 8px; */
    left: 16px;
    right: 16px;
    bottom: auto;
  }
}
</style>
